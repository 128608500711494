@import url(https://fonts.googleapis.com/css?family=Open+Sans);

h2 {
    color: black;
    font-family: 'Open Sans', sans-serif;
    font-size: 2em;
}

.contactform {
    width: 1000px;
    padding: 2em;
    margin-bottom: 5em;
    font-size: 1.1em;
    color: white;
    background: rgba(40, 40, 40, 0.95);
}