body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer {
  background: rgba(40, 40, 40, 0.95);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5em;
  color: white;
  font-family: "Segoe UI", sans-serif;
}

.footer .active {
  color: #fff;
  background-color: #080808;
  font-size: 1.5em;
}

.footer .about {
  font-weight: bold;
  font-family: "Segoe UI", sans-serif;
}

.about .bar {
  float: right;
}

.footer li {
  float: left;
  text-decoration: none;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover {
  color: #f0d66d;
}

.footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

.footer .text {
  font-size: 1.2em;
}

.footer .right {
  float: right;
  font-size: 1.5em;
}
